<template>

  <div class="mg">
    <img class="map1" src="@/assets/images/contactus.jpg" alt="" />
    <div>
      <img class="map2" src="@/assets/images/storemap1.jpg" alt="" />
    </div>
  </div>

</template>

<script>
export default {
  name: "ContactUs",
};
</script>

<style scoped>
.map1 {
  width: 100%;
}
.mg div {
  display: flex;
  justify-content: center;
}
.mg div .map2 {
  width: 70%;
}
</style>